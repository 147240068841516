<template>
  <div class="rentalform" v-if="articleInfo[0]">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step editable :complete="e1 > 1" step="1">
          {{ $t("Werk") }}
          <small v-if="articleInfo[0]">{{ articleInfo[0].autoren_formatiert }}:
            {{ articleInfo[0].i18n[locale].titel1 }}</small>
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :rules="[() => !(e1 > 2 && !auffuehrung.termine.length)]" editable :complete="e1 > 2" step="2">
          {{ $t("Aufführung") }}
          <small v-if="auffuehrung.termine.length">{{ auffuehrung.termine.length }} Termin{{
            auffuehrung.termine.length > 1 ? "e" : ""
          }}</small>
          <small v-if="e1 > 2 && !auffuehrung.termine.length">Keine Termine angegeben!</small>
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step editable :complete="e1 > 3" step="3">
          {{ $t("Material") }}
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step editable :complete="e1 > 4" step="4">
          {{ $t("Interpret / Ausführender") }}
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step editable :complete="e1 > 5" step="5">
          {{ $t("Kontaktdaten") }}
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 6" step="6">
          {{ $t("Prüfen und Senden") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card style="padding: 0.5em">
            <v-row>
              <v-col>
                <h3>{{ $t("Angaben zum Werk") }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" @click="e1 = 2">
                  {{ $t("Weiter") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-card-text v-if="articleInfo[0]">
              <div class="content">
                <div class="content_left">
                  <div class="cover">
                    <v-img width="240"
                      :src="`https://www.carusmedia.com/images-intern/medien/${articleInfo[0].mediafile}`"></v-img>
                  </div>
                </div>
                <div class="content_right">
                  <div class="author">
                    {{ articleInfo[0].autoren_alle_formatiert }}
                  </div>
                  <div class="title1">
                    {{ articleInfo[0].i18n[locale].titel1 }}
                  </div>
                  <div class="worktitle">
                    <div v-if="articleInfo[0].i18n[locale].titel_unter">
                      {{ articleInfo[0].i18n[locale].titel_unter }}
                    </div>
                    <div v-if="articleInfo[0].ref_nr">
                      {{ articleInfo[0].externe_nr }}
                      {{ articleInfo[0].ref_nr }}
                      <span v-if="articleInfo[0].titelmerkmale_mapped.A_SPR">({{ articleInfo[0].titelmerkmale_mapped.A_SPR
                      }})</span>
                    </div>
                    <!--
          {{#if titelmerkmale_mapped.BES}}{{ titelmerkmale_mapped.BES }}{{/if}}
          {{#if titelmerkmale_mapped.DAUER}} / {{ titelmerkmale_mapped.DAUER }} min {{/if}}
          {{#if titelmerkmale_mapped.SCHWIER}} / {{ titelmerkmale_mapped.SCHWIER }}{{/if}}
          -->
                  </div>
                  <p />
                  <p class="intro" v-if="articleInfoLIZ">
                    {{ $t("intro_liz") }}
                  </p>
                  <p class="intro" v-if="articleInfoLEIH">
                    {{ $t("intro_leih") }}
                  </p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card style="padding: 0.5em">
            <v-row class="toprow">
              <v-col class="text-left">
                <v-btn color="primary" @click="e1 = 1" cols="3">
                  {{ $t("Zurück") }}
                </v-btn>
              </v-col>
              <v-col class="text-center" cols="6">
                <h3>{{ $t("Angaben zur Aufführung") }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" @click="e1 = 3" cols="3">
                  {{ $t("Weiter") }}
                </v-btn>
              </v-col>
            </v-row>

            <p>{{ $t("Aufführungsdetails") }}</p>

            <v-form>
              <v-card>
                <v-card-title>{{ $t("Wird das gesamte Werk aufgeführt?") }}
                </v-card-title>
                <v-card-text class="intro">
                  <v-container>
                    <v-row>
                      <v-col class="py-0" cols="3">
                        <v-radio-group v-model="auffuehrung.gesamtauffuehrung">
                          <v-radio :label="$t('Ja')" :value="true"></v-radio>
                          <v-radio :label="$t('Nein')" :value="false"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="6">
                        <v-textarea outlined rows="3" :rules="!auffuehrung.gesamtauffuehrung?inputRequiredRules:[]" :disabled="auffuehrung.gesamtauffuehrung" row-height="1em"
                          v-model="auffuehrung.auffuehrungsteile" :class="{ required: !auffuehrung.gesamtauffuehrung }"
                          :label="$t('Aufgeführte Teile (bitte angeben)')"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>
                  {{ $t("Aufführungsart") }}
                </v-card-title>
                <v-card-text class="intro">
                  <v-container>
                    <v-row>
                      <v-col class="py-0" cols="3">
                        <v-radio-group v-model="auffuehrung.auffuehrungsart">
                          <v-radio :label="$t('konzertante Aufführung')" prepend-icon="mdi-violin"
                            value="konzertant"></v-radio>
                          <v-radio :label="$t('szenische Aufführung')" prepend-icon="mdi-drama-masks"
                            value="szenisch"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="6">
                        <v-textarea outlined v-model="auffuehrung.ergaenzendewerke" :label="$t(
                          'Welche Werke ergänzen das Programm (bei nicht abendfüllenden Werken)?'
                        )
                          ">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-alert dense class="mt-2" type="info" v-if="articleInfoLIZ &&
                      articleInfoLIZ.kosten &&
                      auffuehrung.auffuehrungsart === 'szenisch' &&
                      auffuehrung.gesamtauffuehrung
                      ">
                      <h4>{{ $t("Kosten für szenische Aufführungen") }}</h4>
                      <div v-for="(k, idx) in articleInfoLIZKostenSorted || []" :key="idx">
                        {{ $t(k.aufl_text) }} {{ k.preis | formatCurrency }}
                      </div>
                    </v-alert>
                  </v-container>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  {{ $t("Aufführungstermine") }}
                </v-card-title>
                <v-card-text class="intro">
                  <v-data-table :headers="headers" :items="auffuehrung.termine">
                    <template v-slot:[`item.datde`]="props">
                      <v-text-field v-model="props.item.dateFormatted" single-line dense small @blur="
                        props.item.date = parseDate(props.item.dateFormatted)
                        "></v-text-field>

                      <v-edit-dialog :return-value.sync="props.item.date">
                        icon
                        <template v-slot:input>
                          <v-date-picker v-model="props.item.date" no-title scrollable :locale="locale" @change="
                            props.item.dateFormatted = formatDate(
                              props.item.date
                            )
                            ">
                          </v-date-picker>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:[`item.date`]="props">
                      <v-text-field v-model="props.item.date" :append-icon="istodayorpast(props.item.date) ? 'mdi-alert' : ''"
                        single-line dense small :error="istodayorpast(props.item.date)" type="date"></v-text-field>
                    </template>

                    <template v-slot:[`item.city`]="props">
                      <v-text-field v-model="props.item.city" single-line dense :rules="nameRules" class="required"></v-text-field>
                    </template>

                    <template v-slot:[`item.location`]="props">
                      <v-text-field v-model="props.item.location" single-line dense :rules="nameRules" class="required"></v-text-field>
                    </template>

                    <template v-slot:[`item.size`]="props">
                      <v-text-field v-model.number="props.item.size" single-line dense type="number"
                        :rules="aboveOrEqual0AndIntegerRules" :min="0"></v-text-field>
                    </template>

                    <template v-slot:[`item.seats`]="props">
                      <v-text-field v-model.number="props.item.seats" single-line dense type="number"
                        :rules="aboveOrEqual0AndIntegerRules" :min="0"></v-text-field>
                    </template>

                    <template v-slot:[`item.price`]="props">
                      <v-text-field v-model.number="props.item.price" single-line dense type="number"
                        :rules="aboveOrEqual0Rules" :min="0" suffix=" EUR" style="min-width: 4em"></v-text-field>
                    </template>

                    <template v-slot:[`item.fee`]="props">
                      <div style="
                          white-space: nowrap;
                          min-width: 4em;
                          text-align: right;
                        " v-if="articleInfoLIZ &&
                          articleInfoLIZ.kosten &&
                          auffuehrung.auffuehrungsart === 'szenisch' &&
                          auffuehrung.gesamtauffuehrung
                          ">
                        {{
                          getLicenseFeeForPerformance(props.item, props.index)
                          | formatCurrency
                        }}
                      </div>
                      <div v-else>?</div>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                      <v-btn color="success" dark @click="addTermin">{{
                        $t("Termin hinzufügen")
                      }}</v-btn></template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="deleteTermin(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card style="padding: 0.5em">
            <v-row class="toprow">
              <v-col class="text-left">
                <v-btn color="primary" @click="e1 = 2" cols="3">
                  {{ $t("Zurück") }}
                </v-btn>
              </v-col>
              <v-col class="text-center" cols="6">
                <h3>{{ $t("Gewünschtes Leihmaterial") }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" @click="e1 = 4" cols="3">
                  {{ $t("Weiter") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-form
              v-if="articleInfoLEIH || articleInfoLIZ && (articleInfo[0].leihmaterial.length || articleInfo[0].zusatzmaterial.length)">
              <v-card>
                <v-card-title>{{ $t("Leihmaterial") }}</v-card-title>
                <v-card-text class="intro">
                  <div v-if="(articleInfo[0].leihmaterial || []).length" class="my-2">{{ $t("Intro Stimmensets") }}</div>
                  <v-alert type="info" v-else prominent outlined class="ma-6">
                    {{ $t("nur_kaufmaterial") }}
                    <a target="_blank" :href="`https://www.carus-verlag.com/${articleId}`">{{ $t("Webshop") }}</a>.
                  </v-alert>
                  <v-container v-if="articleInfo[0].leihmaterial.find((lm) =>
                    lm.prod_typ.endsWith('-D')
                  )
                    ">
                    <v-row>
                      <v-col class="py-0" cols="12">
                        <v-radio-group row v-model="material.digital">
                          <v-radio :label="$t('Digitale Noten')" :value="true"></v-radio>
                          <v-radio :label="$t('Physische Noten')" :value="false"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container>
                    <v-row v-for="m in leihmaterialtolist.filter((l) =>
                      prodTypesToShowAbove.includes(l.prod_typ)
                    )" :key="m.artikel_nr" :class="`${m.prod_typ} ${(m.merkmale || [])
  .map((m) => `${m.id}_${m.qaus_id}`)
  .join(' ')}`">
                      <ArticleInput :m="m" :disabled="entryDisabled(m)" v-model.number="material[m.artikel_nr]">
                      </ArticleInput>

                    </v-row>
                    <v-row v-for="m in leihmaterialtolist.filter(
                      (l) =>
                        !prodTypesToShowAbove.includes(l.prod_typ) &&
                        ['STSE', 'ST-D'].includes(l.prod_typ) &&
                        l.merkmale_mapped.INHALT &&
                        l.merkmale_mapped.INHALT.qaus_id !== 'HARM'
                    )" :key="m.artikel_nr" :class="`${m.prod_typ} ${(m.merkmale || [])
  .map((m) => `${m.id}_${m.qaus_id}`)
  .join(' ')}`">
                      <ArticleInput :m="m" :disabled="entryDisabled(m)" v-model.number="material[m.artikel_nr]">
                      </ArticleInput>
                    </v-row>
                    <v-expansion-panels v-model="panel" v-if="articleInfoLEIH">
                      <v-expansion-panel :disabled="einzelstimmendisabled">
                        <v-expansion-panel-header>
                          {{ $t("Einzelstimmen") }}
                          <span class="ml-2" v-if="einzelstimmendisabled && material.digital">
                            {{ $t('Bei digitalen Werken ist keine Einzelauswahl möglich') }}</span>
                          <span class="ml-2" v-else-if="einzelstimmendisabled && leihmaterialtolist.filter(
                            (l) =>
                              !prodTypesToShowAbove.includes(l.prod_typ) &&
                              (!['STSE', 'ST-D'].includes(l.prod_typ) ||
                                (l.merkmale_mapped.INHALT &&
                                  l.merkmale_mapped.INHALT.qaus_id ===
                                  'HARM'))
                          ).length">{{
  $t(
    "Auswahl nicht möglich, komplettes Orchestermaterial angewählt!"
  )
}}</span>
                          <span class="ml-2" v-else-if="einzelstimmendisabled">{{ $t(
                            "Auswahl nicht möglich, keine Einzelstimmen vorhanden!"
                          ) }}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-container>
                            <v-row v-for="m in leihmaterialtolist.filter(
                              (l) =>
                                !prodTypesToShowAbove.includes(l.prod_typ) &&
                                (!['STSE', 'ST-D'].includes(l.prod_typ) ||
                                  (l.merkmale_mapped.INHALT &&
                                    l.merkmale_mapped.INHALT.qaus_id ===
                                    'HARM'))
                            )" :key="m.artikel_nr" :class="`${m.prod_typ} ${(m.merkmale || [])
  .map((m) => `${m.id}_${m.qaus_id}`)
  .join(' ')}`">
                              <ArticleInput :m="m" :disabled="entryDisabled(m)" v-model.number="material[m.artikel_nr]">
                              </ArticleInput>

                            </v-row>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-container>
                  <v-alert dense type="info" v-if="canCalculateFeeForRentalMaterial">
                <h4>{{ $t("Kosten für Leihmaterial") }}</h4>
                <div v-for="(k, idx) in effectiveKostenSorted" :key="idx">
                  {{ $t(`${k.aufl_text}${isFall01 ? '_FALL_01' : ''}`) }} {{ k.preis | formatCurrency }}
                </div>
                <div v-if="effectiveKosten.length === 0">{{ $t('Angebot') }}</div>
              </v-alert>
              <v-alert dense type="info" v-else-if="!auffuehrung.gesamtauffuehrung && hasSpecifiedKosten">
                {{ $t('Angebot') }}
              </v-alert>
                </v-card-text>

              </v-card>

              <v-card v-if="articleInfo[0].kaufmaterial.concat((articleInfo[0].leihmaterial || []).some((lm) => lm.artikel_nr === articleInfo[0].haupt_artikel_nr) ? undefined : { // TODO: nur wenn nich im leihmaterial aufgeführt?
                merkmale_mapped: articleInfo[0].titelmerkmale_mapped,
                prod_typ: articleInfo[0].prod_typ,
                prod_typ_bez: articleInfo[0].prod_typ_bez,
                artikel_nr_formatiert:
                  articleInfo[0].artikel_nr_formatiert,
                artikel_nr: articleInfo[0].haupt_artikel_nr,
                aufl_dat_soll: articleInfo[0].aufl_dat_soll,
                meld_nr: articleInfo[0].meld_nr
              })
                .filter((km) => !!km).length">
                <v-card-title>{{ $t("Kaufmaterial") }}&nbsp;&nbsp;&nbsp;<a target="_new"
                    :href="`https://www.carus-verlag.com/${articleInfo[0].haupt_artikel_nr}`">{{ $t("Im Carus-Webshop ansehen") }}</a></v-card-title>
                <v-card-text class="intro">
                  <h5 v-if="articleInfo[0].kaufmaterial.filter(
                      (km) => km.artikel_nr.length <= 7
                    ).concat((articleInfo[0].leihmaterial || []).some((lm) => lm.artikel_nr === articleInfo[0].haupt_artikel_nr) ? undefined : { // TODO: nur wenn nich im leihmaterial aufgeführt?
                      merkmale_mapped: articleInfo[0].titelmerkmale_mapped,
                      prod_typ: articleInfo[0].prod_typ,
                      prod_typ_bez: articleInfo[0].prod_typ_bez,
                      artikel_nr_formatiert:
                        articleInfo[0].artikel_nr_formatiert,
                      artikel_nr: articleInfo[0].haupt_artikel_nr,
                      aufl_dat_soll: articleInfo[0].aufl_dat_soll,
                      meld_nr: articleInfo[0].meld_nr
                    })
                      .filter((km) => !!km)
                    " class="mt-1">
                    {{ $t("Print-Ausgaben & CDs") }}
                  </h5>
                  <v-container>
                    <v-row v-for="(m, idx) in articleInfo[0].kaufmaterial
                      .filter((km) => km.artikel_nr.length <= 7)
                      .concat((articleInfo[0].leihmaterial || []).some((lm) => lm.artikel_nr === articleInfo[0].haupt_artikel_nr) ? undefined : { // TODO: nur wenn nich im leihmaterial aufgeführt?
                        merkmale_mapped: articleInfo[0].titelmerkmale_mapped,
                        prod_typ: articleInfo[0].prod_typ,
                        prod_typ_bez: articleInfo[0].prod_typ_bez,
                        artikel_nr_formatiert:
                          articleInfo[0].artikel_nr_formatiert,
                        artikel_nr: articleInfo[0].haupt_artikel_nr,
                        aufl_dat_soll: articleInfo[0].aufl_dat_soll,
                        meld_nr: articleInfo[0].meld_nr
                      })
                      .filter((km) => !!km)
                      .toSorted((a, b) =>
                        a.prod_typ === 'PART' && b.prod_typ !== 'PART'
                          ? -1
                          : b.prod_typ === 'PART' && a.prod_typ !== 'PART'
                            ? 1
                            : a.artikel_nr.localeCompare(b.artikel_nr)
                      )" :key="idx">
                      <v-col class="py-0" cols="9">
                        {{ $t(`prod_typ.${m.prod_typ}`)
                        }}<span v-if="(m.merkmale_mapped || {}).ALTERN">, {{
  $t(`ALTERN.${m.merkmale_mapped.ALTERN.qaus_id}`)
}}</span><span v-if="(m.merkmale_mapped || {}).INHALT ||
    (m.merkmale_mapped || {}).INSTR ||
    (m.merkmale_mapped || {}).VERTRAG ||
    (m.merkmale_mapped || {}).FORM ||
    (m.merkmale_mapped || {}).TONART ||
    (m.merkmale_mapped || {}).TEXTART
    ">, {{
      (m.merkmale_mapped.INHALT &&
        $t(
          `INHALT.${m.merkmale_mapped.INHALT.qaus_id}`
        )) ||
      (m.merkmale_mapped.INSTR &&
        $t(`INSTR.${m.merkmale_mapped.INSTR.qaus_id}`)) ||
      (m.merkmale_mapped.VERTRAG &&
        $t(
          `VERTRAG.${m.merkmale_mapped.VERTRAG.qaus_id}`
        )) ||
      (m.merkmale_mapped.FORM &&
        $t(`FORM.${m.merkmale_mapped.FORM.qaus_id}`)) ||
      (m.merkmale_mapped.TEXTART &&
        $t(
          `TEXTART.${m.merkmale_mapped.TEXTART.qaus_id}`
        )) ||
      (m.merkmale_mapped.TONART &&
        $t(
          `TONART.${m.merkmale_mapped.TONART.qaus_id}`
        ))
    }}</span>, Carus {{ m.artikel_nr_formatiert }} <span
                          style="display:block;margin-top:-5px;color:orange" v-if="m.meld_nr === '21'">{{
                            $t(`meld_nr.${m.meld_nr}`) }}<span v-if="m.aufl_dat_soll">, {{ $t('voraussichtlich lieferbar ab') }} {{ m.aufl_dat_soll |
    formatDate('MM/YYYY') }}</span></span>
                      </v-col>
                    </v-row>
                  </v-container>
                  <h5 v-if="articleInfo[0].kaufmaterial.filter(
                    (km) => km.artikel_nr.length > 7
                  ).length
                    " class="mt-1">
                    {{ $t("Downloads") }}
                  </h5>
                  <v-container>
                    <v-row v-for="(m, idx) in articleInfo[0].kaufmaterial
                      .filter((km) => km.artikel_nr.length > 7)
                      .toSorted((a, b) =>
                        a.prod_typ === 'PA-D' && b.prod_typ !== 'PA-D'
                          ? -1
                          : b.prod_typ === 'PA-D' && a.prod_typ !== 'PA-D'
                            ? 1
                            : a.artikel_nr.localeCompare(b.artikel_nr)
                      )" :key="idx">
                      <v-col class="py-0" cols="9">
                        {{ $t(`prod_typ.${m.prod_typ}`)
                        }}<span v-if="(m.merkmale_mapped || {}).ALTERN">, {{
  $t(`ALTERN.${m.merkmale_mapped.ALTERN.qaus_id}`)
}}</span><span v-if="(m.merkmale_mapped || {}).INHALT ||
    (m.merkmale_mapped || {}).INSTR ||
    (m.merkmale_mapped || {}).VERTRAG ||
    (m.merkmale_mapped || {}).TONART ||
    (m.merkmale_mapped || {}).TEXTART
    ">,&nbsp;{{
      (m.merkmale_mapped.INHALT &&
        $t(
          `INHALT.${m.merkmale_mapped.INHALT.qaus_id}`
        )) ||
      (m.merkmale_mapped.INSTR &&
        $t(`INSTR.${m.merkmale_mapped.INSTR.qaus_id}`)) ||
      (m.merkmale_mapped.VERTRAG &&
        $t(
          `VERTRAG.${m.merkmale_mapped.VERTRAG.qaus_id}`
        )) ||
      (m.merkmale_mapped.TEXTART &&
        $t(
          `TEXTART.${m.merkmale_mapped.TEXTART.qaus_id}`
        )) ||
      (m.merkmale_mapped.TONART &&
        $t(
          `TONART.${m.merkmale_mapped.TONART.qaus_id}`
        ))
    }}</span>, Carus {{ m.artikel_nr_formatiert }} <span
                          style="display:block;margin-top:-5px;color:orange" v-if="m.meld_nr === '21'">{{
                            $t(`meld_nr.${m.meld_nr}`) }}<span v-if="m.aufl_dat_soll">, {{ $t('voraussichtlich lieferbar ab') }} {{ m.aufl_dat_soll |
                              formatDate('MM/YYYY') }}</span></span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <v-card v-if="articleInfo[0].zusatzmaterial.length || articleInfo[0].pmfile
                ">
                <v-card-title>{{ $t("Zusatzmaterial") }}</v-card-title>
                <v-card-text class="intro">
                  <v-container class="mt-2">
                    <v-row v-for="(m, idx) in articleInfo[0].zusatzmaterial" :key="idx">
                      <ArticleInput :m="m" v-model.number="material[m.artikel_nr]"
                        show-preise></ArticleInput>
                    </v-row>
                    <v-row v-if="articleInfo[0].pmfile">
                      {{ $t("Bildmaterial für Ihre Plakatgestaltung") }}
                      <a class="ml-1" :href="`https://www.carusmedia.com/images-intern/medien/${articleInfo[0].pmfile}`"
                        target="_blank">
                        {{ $t(">> zum Download") }}</a>
                      <v-img class="mt-2"></v-img>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>


            </v-form>
            <v-alert type="info" v-else prominent outlined class="ma-6">
              {{ $t("nur_kaufmaterial") }}
              <a target="_blank" :href="`https://www.carus-verlag.com/${articleId}`">{{ $t("Webshop") }}</a>.
            </v-alert>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card style="padding-top: 0em">
            <v-row class="toprow">
              <v-col class="text-left">
                <v-btn color="primary" @click="e1 = 3" cols="3">
                  {{ $t("Zurück") }}
                </v-btn>
              </v-col>
              <v-col class="text-center" cols="6">
                <h3>{{ $t("Angaben zu Interpreten / Ausführenden") }}</h3>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" @click="e1 = 5" cols="3">
                  {{ $t("Weiter") }}
                </v-btn>
              </v-col>
            </v-row>

            <p>
              {{ $t("intro_interpreten") }}
            </p>
            <v-form>
              <v-card>
                <v-card-title>{{ $t("Interpreten / Ausführende") }}
                </v-card-title>
                <v-card-text class="intro">
                  <v-container>
                    <v-row class="mt-1">
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense v-model="interpret.dirigent" :rules="nameRules" class="required"
                          label="Dirigent*in"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense v-model="interpret.chorname" :rules="nameRules" class="required"
                          label="Chor (Name)"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense v-model="interpret.orchester" :rules="nameRules" class="required"
                          label="Orchester (Name)"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="12">
                        <v-radio-group row v-model="interpret.orchesterart">
                          <v-radio :label="$t('Berufsorchester')" value="berufsorchester"></v-radio>
                          <v-radio :label="$t('Laienorchester')" value="laienorchester"
                            @click="interpret.orchestereinstufung = (hasOrchkatKM ? 'ORCHKAT_KM' : 'ORCHKAT_D')"></v-radio>
                          <v-radio :label="$t('Orchester aus Berufs- und Laienmusikern')
                            " value="projektorchester" @click="interpret.orchestereinstufung = 'ORCHKAT_C'"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-select v-model="interpret.orchestereinstufung"
                          :items="orchestereinstufung.filter((oe) => interpret.orchesterart === 'laienorchester' ? oe.id === (hasOrchkatKM ? 'ORCHKAT_KM' : 'ORCHKAT_D') : (interpret.orchesterart === 'projektorchester' ? oe.id === 'ORCHKAT_C' : oe.id != 'ORCHKAT_KM'))"
                          :item-text="(i) => $t(`orchestereinstufung.${i.id}`)" item-value="id"
                          :label="$t('Einstufung / Kategorie')" dense outlined :disabled="!['berufsorchester'].includes(
                            interpret.orchesterart
                          )
                            ">
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <div v-html="$t('info_einstufung')"></div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>{{ $t("Aufzeichnungsart und -verwendung") }}
                </v-card-title>
                <v-card-text class="intro">
                  <v-container>
                    <h5>{{ $t("Aufzeichnungsart") }}</h5>
                    <v-row>
                      <v-col class="py-0" cols="3">
                        <v-checkbox v-model="interpret.keineaufzeichnung" :label="$t('keine Aufzeichnung')"
                          prepend-icon="mdi-mute" @change="
                            interpret.keineaufzeichnung &&
                            ((interpret.audioaufzeichnung = false),
                              (interpret.audiovisuelleaufzeichnung = false))
                            "></v-checkbox>
                      </v-col>
                      <v-col class="py-0" cols="3">
                        <v-checkbox v-model="interpret.audioaufzeichnung" @change="
                          (interpret.audioaufzeichnung ||
                            interpret.audiovisuelleaufzeichnung) &&
                          (interpret.keineaufzeichnung = false)
                          " :label="$t('Audioaufzeichnung')" prepend-icon="mdi-microphone"></v-checkbox>
                      </v-col>
                      <v-col class="py-0" cols="3">
                        <v-checkbox @change="
                          (interpret.audioaufzeichnung ||
                            interpret.audiovisuelleaufzeichnung) &&
                          (interpret.keineaufzeichnung = false)
                          " v-model="interpret.audiovisuelleaufzeichnung" :label="$t('Audiovisuelle Aufzeichnung')"
                          prepend-icon="mdi-video"></v-checkbox>
                      </v-col>
                    </v-row>
                    <h5>{{ $t("Aufzeichnung für") }}</h5>
                    <v-row>
                      <v-col class="py-0" cols="2">
                        <v-checkbox v-model="interpret.radio" :label="$t('Hörfunk')"
                          prepend-icon="mdi-radio"></v-checkbox>
                      </v-col>
                      <v-col class="py-0" cols="2">
                        <v-checkbox v-model="interpret.tv" :label="$t('TV')"
                          prepend-icon="mdi-television-classic"></v-checkbox>
                      </v-col>
                      <v-col class="py-0" cols="3">
                        <v-checkbox v-model="interpret.youtube" :label="$t('Videoplatform (YouTube o.ä.)')"
                          prepend-icon="mdi-youtube"></v-checkbox>
                      </v-col>
                      <v-col class="py-0" cols="2">
                        <v-checkbox v-model="interpret.website" :label="$t('eigene Website')"
                          prepend-icon="mdi-web"></v-checkbox>
                      </v-col>
                      <v-col class="py-0" cols="2">
                        <v-checkbox v-model="interpret.archive" :label="$t('eigenes Archiv')"
                          prepend-icon="mdi-archive"></v-checkbox>
                      </v-col>
                      <v-text-field outlined dense v-model="interpret.other" :label="$t('Sonstiges (bitte angeben)')"
                        prepend-icon="mdi-help-circle"></v-text-field>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card style="padding: 0.5em">
            <v-row class="toprow">
              <v-col class="text-left">
                <v-btn color="primary" @click="e1 = 4" cols="3">
                  {{ $t("Zurück") }}
                </v-btn>
              </v-col>
              <v-col class="text-center" cols="6">
                <h3>{{ $t("Kontaktinformationen") }}</h3>
              </v-col>
              <v-col class="text-right" cols="3">
                <v-btn color="primary" @click="e1 = 6">
                  {{ $t("Prüfen und Senden") }}
                </v-btn>
              </v-col>
            </v-row>

            <p></p>
            <v-card>
              <v-card-title>{{
                $t("lieferung_und_versand_titel")
              }}</v-card-title>
              <v-card-text>
                {{ $t("lieferung_und_versand_text") }}

                <v-container>
                  <v-row class="my-0 py-0">
                    <v-col class="py-0 my-0">
                      <v-menu v-model="menu" :close-on-content-click="true" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :value="formattedDeliveryDate" :label="$t('Versandtermin')"
                            prepend-icon="mdi-calendar" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="contact.deliverydate" :min="minDate" no-title scrollable :locale="locale"
                          first-day-of-week="1">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">{{
                            $t("Cancel")
                          }}</v-btn>
                          <v-btn text color="primary" @click="menu = false">{{
                            $t("OK")
                          }}</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="my-0 py-0">
                    <v-col class="py-0 my-0" cols="8">
                      <v-checkbox v-model="contact.deliveryacceptcost" :label="$t('deliveryacceptcost')"
                        prepend-icon="mdi-truck-delivery"></v-checkbox>
                    </v-col>
                    <v-col>
                      <!-- Link to https://www.carus-verlag.com/bestellung-versand/ -->
                      <v-btn color="primary" text target="_blank" href="https://www.carus-verlag.com/bestellung-versand/">
                        {{ $t("Versandkosten") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <p>{{ $t("Einleitung Kontaktinfos") }}</p>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>{{
                $t("Kontaktinformationen")
              }}&nbsp;&nbsp;&nbsp;<v-simple-checkbox v-if="delivery.enabled || billing.enabled"
                  v-model="contact.contract"></v-simple-checkbox><span v-if="delivery.enabled || billing.enabled">{{
                    $t("Vertragspartner")
                  }}</span></v-card-title>
              <v-card-text class="intro">
                <v-form>
                  <v-container>
                    <v-row class="mt-1">
                      <v-col class="py-0" cols="8">
                        <v-text-field outlined dense v-model="contact.institution"
                          :label="$t('Institution')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <v-text-field outlined dense v-model="contact.vat" :label="$t('VAT_if_present')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="2">
                        <v-select outlined dense :items="[$t('Herr'), $t('Frau'), $t('keine')]"
                          v-model="contact.salutation" :label="$t('Anrede')"></v-select>
                      </v-col>
                      <v-col class="py-0" cols="5">
                        <v-text-field outlined dense v-model="contact.firstname" :rules="nameRules" class="required"
                          :label="$t('Vorname')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="5">
                        <v-text-field outlined dense v-model="contact.lastname" :rules="nameRules" class="required"
                          :label="$t('Nachname')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-text-field outlined dense v-model="contact.street" :rules="nameRules" class="required"
                          :label="$t('Strasse/Hausnummer')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="3">
                        <v-text-field outlined dense v-model="contact.zipcode" :rules="nameRules" class="required"
                          :label="$t('PLZ')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="9">
                        <v-text-field outlined dense v-model="contact.city" :rules="nameRules" class="required"
                          :label="$t('Stadt')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-select outlined dense class="required" :item-text="(i) =>
                          $t(
                            `country.${i.cover_code ? i.cover_code + '_' : ''
                            }${i.code}`
                          )
                          " :items="countries" :item-value="(i) => i" v-model="contact.country" :label="$t('Land')">
                        </v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense type="tel" v-model="contact.phone" :label="$t('Telefon')"
                          :rules="phoneRules" @keypress="isPhoneNumberCharacter" class="required"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense type="email" :rules="emailRules" class="required"
                          v-model="contact.email" :label="$t('E-Mail')"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0">
                        <v-text-field outlined dense v-model="contact.organizer" :rules="nameRules" class="required"
                          :label="$t('Veranstalter')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>

            <v-card>
              <v-card-title><v-simple-checkbox v-model="billing.enabled"></v-simple-checkbox>{{
                $t("Abweichende Rechnungsadresse")
              }}&nbsp;&nbsp;&nbsp;<v-simple-checkbox v-if="billing.enabled"
                  v-model="billing.contract"></v-simple-checkbox><span v-if="billing.enabled">{{
                    $t("Vertragspartner")
                  }}</span></v-card-title>
              <v-card-text class="intro">
                <v-form v-if="billing.enabled">
                  <v-container>
                    <v-row class="mt-1">
                      <v-col class="py-0">
                        <v-text-field outlined dense v-model="billing.institution"
                          :label="$t('Institution')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <v-text-field outlined dense v-model="billing.vat" :label="$t('VAT_if_present')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="2">
                        <v-select outlined dense :items="['Herr', 'Frau', 'keine']" v-model="billing.salutation"
                          :label="$t('Anrede')"></v-select>
                      </v-col>
                      <v-col class="py-0" cols="5">
                        <v-text-field outlined dense :rules="nameRules" class="required" v-model="billing.firstname"
                          :label="$t('Vorname')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="5">
                        <v-text-field outlined dense :rules="nameRules" class="required" v-model="billing.lastname"
                          :label="$t('Nachname')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-text-field outlined dense v-model="billing.street" :rules="nameRules" class="required"
                          :label="$t('Strasse/Hausnummer')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="3">
                        <v-text-field outlined dense v-model="billing.zipcode" :rules="nameRules" class="required"
                          :label="$t('PLZ')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="9">
                        <v-text-field outlined dense v-model="billing.city" :rules="nameRules" class="required"
                          :label="$t('Stadt')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-select outlined dense class="required" :item-text="(i) =>
                          $t(
                            `country.${i.cover_code ? i.cover_code + '_' : ''
                            }${i.code}`
                          )
                          " :items="countries" :item-value="(i) => i" v-model="billing.country" :label="$t('Land')">
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense type="tel" v-model="billing.phone" @keypress="isPhoneNumberCharacter"
                          :label="$t('Telefon')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense type="email" :rules="emailRules" class="required"
                          v-model="billing.email" :label="$t('E-Mail')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>

            <v-card>
              <v-card-title><v-simple-checkbox v-model="delivery.enabled"></v-simple-checkbox>{{
                $t("Abweichende Lieferadresse")
              }}&nbsp;&nbsp;&nbsp;<v-simple-checkbox v-if="delivery.enabled"
                  v-model="delivery.contract"></v-simple-checkbox><span v-if="delivery.enabled">{{
                    $t("Vertragspartner")
                  }}</span></v-card-title>
              <v-card-text class="intro">
                <v-form v-if="delivery.enabled">
                  <v-container>
                    <v-row class="mt-1">
                      <v-col class="py-0">
                        <v-text-field outlined dense v-model="delivery.institution"
                          :label="$t('Institution')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="2">
                        <v-select outlined dense :items="['Herr', 'Frau', 'keine']" v-model="delivery.salutation"
                          :label="$t('Anrede')"></v-select>
                      </v-col>
                      <v-col class="py-0" cols="5">
                        <v-text-field outlined dense v-model="delivery.firstname" :rules="nameRules" class="required"
                          :label="$t('Vorname')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="5">
                        <v-text-field outlined dense v-model="delivery.lastname" :rules="nameRules" class="required"
                          :label="$t('Nachname')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-text-field outlined dense :rules="nameRules" class="required" v-model="delivery.street"
                          :label="$t('Strasse/Hausnummer')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="3">
                        <v-text-field outlined dense v-model="delivery.zipcode" :rules="nameRules" class="required"
                          :label="$t('PLZ')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="9">
                        <v-text-field outlined dense :rules="nameRules" class="required" v-model="delivery.city"
                          :label="$t('Stadt')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-select outlined dense class="required" :item-text="(i) =>
                          $t(
                            `country.${i.cover_code ? i.cover_code + '_' : ''
                            }${i.code}`
                          )
                          " :items="countries" :item-value="(i) => i" v-model="delivery.country" :label="$t('Land')">
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense type="tel" v-model="delivery.phone" :rules="phoneRules"
                          class="required" @keypress="isPhoneNumberCharacter" :label="$t('Telefon')"></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="6">
                        <v-text-field outlined dense type="email" :rules="emailRules" class="required"
                          v-model="delivery.email" :label="$t('E-Mail')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="6">
          <v-card style="padding: 0.5em">
            <v-row class="toprow">
              <v-col class="text-left">
                <v-btn color="primary" @click="e1 = 5" cols="3">
                  {{ $t("Zurück") }}
                </v-btn>
              </v-col>
              <v-col class="text-center" cols="6">
                <h3>{{ $t("Prüfen und Senden") }}</h3>
              </v-col>
              <v-col class="text-right" cols="3">
                <v-btn color="success" @click="checkAndSend" :disabled="!canSend()">
                  {{ $t("Senden") }}
                </v-btn>
              </v-col>
            </v-row>

            <p>{{ $t("angaben_ueberpruefen") }}</p>

            <v-form>
              <v-card>
                <v-card-title>{{ $t("auffuehrung_von") }}&nbsp;<span v-if="articleInfo[0]">{{
                  articleInfo[0].autoren_formatiert }}:
                    {{ articleInfo[0].i18n[locale].titel1 }}</span></v-card-title>
                <v-card-text class="intro">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                $t("Aufgeführte Werkteile")
                              }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <span v-if="auffuehrung.gesamtauffuehrung">{{
                                $t("Gesamtaufführung")
                              }}</span>
                              <span v-else>
                                <v-icon color="error" v-if="!auffuehrung.gesamtauffuehrung && !auffuehrung.auffuehrungsteile">mdi-alert</v-icon><span v-if="!auffuehrung.gesamtauffuehrung && !auffuehrung.auffuehrungsteile">{{ $t('auffuehrungsteile_missing') }}</span>{{
                                auffuehrung.auffuehrungsteile
                              }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("Ergänzende Werke")
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <span v-if="!auffuehrung.ergaenzendewerke">{{
                                $t("keine")
                              }}</span>
                              <span v-else>{{ auffuehrung.ergaenzendewerke }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("Aufführungsart")
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              auffuehrung.auffuehrungsart
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("Dirigent*in")
                            }}</v-list-item-title>
                            <v-list-item-subtitle><span v-if="interpret.dirigent">{{
                              interpret.dirigent
                            }}</span>
                              <v-alert v-else type="error">{{
                                $t("Angabe fehlt!")
                              }}</v-alert></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("Chor")
                            }}</v-list-item-title>
                            <v-list-item-subtitle><span v-if="interpret.chorname">{{
                              interpret.chorname
                            }}</span>
                              <v-alert v-else type="error">{{
                                $t("Angabe fehlt!")
                              }}</v-alert></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("Orchester")
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <div v-if="interpret.orchester">
                                {{ interpret.orchester }} (
                                {{ $t(interpret.orchesterart) }} )
                              </div>
                              <v-alert v-else type="error">{{
                                $t("Angabe fehlt!")
                              }}</v-alert>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              $t("Aufzeichnungen")
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <span v-if="interpret.audioaufzeichnung ||
                                  interpret.audiovisuelleaufzeichnung
                                  ">
                                {{
                                  [
                                    interpret.audioaufzeichnung &&
                                    $t("Audioaufzeichnung"),
                                    interpret.audiovisuelleaufzeichnung &&
                                    $t("Audiovisuelle Aufzeichnung"),
                                  ]
                                    .filter((x) => !!x)
                                    .join(", ")
                                }}</span>
                              <span v-else>{{ $t("keine") }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("Aufzeichnung für") }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <span v-if="!!interpret.audioaufzeichnung ||
                                !!interpret.audiovisuelleaufzeichnung
                                ">
                                {{
                                  [
                                    interpret.radio && $t("Hörfunk"),
                                    interpret.tv && $t("TV"),
                                    interpret.youtube &&
                                    $t("Videoplatform (YouTube o.ä.)"),
                                    interpret.website && $t("eigene Webseite"),
                                    interpret.archive && $t("eigenes Archiv"),
                                    interpret.other,
                                  ]
                                    .filter((x) => !!x)
                                    .join(", ")
                                }}</span>
                              <span v-else>-</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="!!interpret.audioaufzeichnung ||
                        !!interpret.audiovisuelleaufzeichnung
                        ">
                      <v-col>
                        <v-alert type="info">
                          {{ $t('Preisangaben beziehen sich nur auf das Leihmaterial') }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>{{ $t("Aufführungstermine") }} <v-spacer />
                  <span v-if="articleInfoLIZ &&
                    articleInfoLIZ.kosten &&
                    auffuehrung.auffuehrungsart === 'szenisch' &&
                    auffuehrung.gesamtauffuehrung
                    ">
                    {{
                      auffuehrung.termine.reduce(
                        (p, c, i) => p + getLicenseFeeForPerformance(c, i),
                        0
                      ) | formatCurrency
                    }}
                  </span>
                </v-card-title>
                <v-card-text class="intro">
                  <v-alert dense class="mt-2" v-if="auffuehrung.termine.length === 0" type="error">
                    {{ $t("keine_termine_angegeben") }}
                  </v-alert>
                  <v-alert dense class="mt-2" v-if="auffuehrung.termine.some((t) => istodayorpast(t.date))" type="error">
                    {{ $t("ungueltige_termine_angegeben") }}
                  </v-alert>
                  <v-alert dense class="mt-2" v-if="auffuehrung.termine.some((t) => !t.city || !t.location)" type="error">
                    {{ $t("ungueltige_termine_angegeben_city_loc") }}
                  </v-alert>
                  <v-list-item three-line v-for="(termin, idx) in auffuehrung.termine" :key="idx">
                    <v-list-item-content>
                      <v-list-item-title><v-icon v-if="istodayorpast(termin.date)" color="error">mdi-alert</v-icon>{{
                        formatDate(termin.date) }}, <v-icon v-if="!termin.location" color="error">mdi-alert</v-icon>{{ termin.location || $t('location_missing') }}
                        <v-icon v-if="!termin.city" color="error">mdi-alert</v-icon> {{ termin.city || $t('city_missing') }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("Sitzplätze") }}: {{ termin.seats }},
                        <span v-if="termin.price">{{ termin.price }}&euro; {{ $t("Eintritt") }}</span><span v-else>{{
                          $t("kein Eintritt") }} </span><span v-if="getLicenseFeeForPerformance(termin, idx) &&
    articleInfoLIZ &&
    articleInfoLIZ.kosten &&
    auffuehrung.auffuehrungsart === 'szenisch' &&
    auffuehrung.gesamtauffuehrung
    ">,
                          {{ getLicenseFeeForPerformance(termin, idx) }}&euro;
                          {{ $t("Lizenzkosten") }}</span></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>{{ $t("Leihmaterial") }} <v-spacer />
                  <span v-if="leihmaterialtolist.filter(
                    (x) =>
                      x.artikel_nr in material &&
                      material[x.artikel_nr] > 0 &&
                      !entryDisabled(x)
                  ).length > 0 &&
                    auffuehrung.termine.reduce(
                      (p, c, i) => p + getFeeForRentalMaterial(c, i),
                      0
                    ) > 0
                    ">{{
    auffuehrung.termine.reduce(
      (p, c, i) => p + getFeeForRentalMaterial(c, i),
      0
    ) | formatCurrency
  }}
                  </span>
                </v-card-title>
                <v-card-text class="intro">
                  <v-alert dense class="mt-2" v-if="auffuehrung.termine.length === 0" type="error">
                    {{ $t("keine_termine_angegeben") }}
                  </v-alert>
                  <v-alert dense class="mt-2" type="info" v-else-if="leihmaterialtolist.filter(
                    (x) =>
                      x.artikel_nr in material &&
                      material[x.artikel_nr] > 0 &&
                      !entryDisabled(x)
                  ).length === 0
                    ">
                    {{ $t("Kein Leihmaterial angefordert") }}
                  </v-alert>
                  <v-alert dense class="mt-2" v-else-if="auffuehrung.termine.reduce(
                    (p, c, i) => p + getFeeForRentalMaterial(c, i),
                    0
                  ) === 0
                    " type="info">{{ $t("Angebot") }}</v-alert>
                  <v-list-item v-for="m in leihmaterialtolist.filter(
                    (x) =>
                      x.artikel_nr in material &&
                      material[x.artikel_nr] > 0 &&
                      !entryDisabled(x)
                  )" :key="m.artikel_nr">
                    {{ material[m.artikel_nr] }}x
                    {{ $t(`prod_typ.${m.prod_typ}`) }},&nbsp;<span
                      v-if="m.merkmale_mapped.INHALT || m.merkmale_mapped.INSTR">
                      {{
                        (m.merkmale_mapped.INHALT &&
                          $t(`INHALT.${m.merkmale_mapped.INHALT.qaus_id}`)) ||
                        (m.merkmale_mapped.INSTR &&
                          $t(`INSTR.${m.merkmale_mapped.INSTR.qaus_id}`))
                      }},&nbsp;</span>Carus {{ m.artikel_nr_formatiert }}
                  </v-list-item>
                </v-card-text>
              </v-card>

              <v-card v-if="articleInfo[0].zusatzmaterial && articleInfo[0].zusatzmaterial.length">
                <v-card-title>{{ $t("Zusatzmaterial") }} <v-spacer />
                  <span v-if="articleInfo[0].zusatzmaterial.filter(
                    (x) =>
                      x.artikel_nr in material &&
                      material[x.artikel_nr] > 0
                  ).length > 0
                    ">{{
    articleInfo[0].zusatzmaterial.filter(
      (x) =>
        x.artikel_nr in material &&
        material[x.artikel_nr] > 0
    ).reduce(
      (p, c, i) => p + c.preise[0].preis * material[c.artikel_nr],
      0
    ) | formatCurrency
  }}
                  </span>
                </v-card-title>
                <v-card-text class="intro">
                  <v-alert dense class="mt-2" v-if="auffuehrung.termine.length === 0" type="error">
                    {{ $t("keine_termine_angegeben") }}
                  </v-alert>
                  <v-alert dense class="mt-2" type="info" v-else-if="articleInfo[0].zusatzmaterial.filter(
                    (x) =>
                      x.artikel_nr in material &&
                      material[x.artikel_nr] > 0
                  ).length === 0
                    ">
                    {{ $t("Kein Zusatzmaterial angefordert") }}
                  </v-alert>

                  <v-list-item v-for="m in articleInfo[0].zusatzmaterial.filter(
                    (x) =>
                      x.artikel_nr in material &&
                      material[x.artikel_nr] > 0
                  )" :key="m.artikel_nr">
                    {{ material[m.artikel_nr] }}x
                    {{ $t(`prod_typ.${m.prod_typ}`) }},&nbsp;<span
                      v-if="m.merkmale_mapped.INHALT || m.merkmale_mapped.INSTR">
                      {{
                        (m.merkmale_mapped.INHALT &&
                          $t(`INHALT.${m.merkmale_mapped.INHALT.qaus_id}`)) ||
                        (m.merkmale_mapped.INSTR &&
                          $t(`INSTR.${m.merkmale_mapped.INSTR.qaus_id}`))
                      }},&nbsp;</span>Carus {{ m.artikel_nr_formatiert }}
                  </v-list-item>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>{{ $t("Kontaktdaten") }} </v-card-title>
                <v-card-text class="intro">
                  <v-container>
                    <v-row>
                      <div v-if="sufficientContact(contact, false, true)">
                        {{ contact.institution }}<br />
                        {{
                          contact.salutation && contact.salutation != "keine"
                          ? contact.salutation
                          : ""
                        }}
                        {{ contact.firstname }} {{ contact.lastname }}<br />
                        {{ contact.street }}<br />
                        {{ contact.zipcode }} {{ contact.city }}<br />
                        {{
                          $t(
                            `country.${contact.country.cover_code
                              ? contact.country.cover_code + "_"
                              : ""
                            }${contact.country.code}`
                          )
                        }}<br />
                        {{ contact.phone }}<br />
                        {{ contact.email }}<br />
                        {{ contact.organizer }}<br />
                        {{ contact.vat ? `${$t('VAT')} ${contact.vat}` : '' }}<br v-if="contact.vat" />
                        {{ contact.contract ? $t("Vertragspartner") : "" }}
                      </div>
                      <div v-else>
                        <v-alert class="mt-2" type="error" outlined dense>{{
                          $t("Kontaktdaten unvollständig!")
                        }}</v-alert>
                      </div>
                      <v-spacer v-if="billing.enabled" />
                      <div v-if="billing.enabled">
                        <strong>{{ $t("Rechnungsadresse") }}</strong><br />
                        <div v-if="sufficientContact(billing, true)">
                          {{ billing.institution }}<br />
                          {{
                            billing.salutation && billing.salutation != "keine"
                            ? billing.salutation
                            : ""
                          }}
                          {{ billing.firstname }} {{ billing.lastname }}<br />
                          {{ billing.street }}<br />
                          {{ billing.zipcode }} {{ billing.city }}<br />
                          {{
                            $t(
                              `country.${billing.country.cover_code
                                ? billing.country.cover_code + "_"
                                : ""
                              }${billing.country.code}`
                            )
                          }}<br />
                          {{ billing.phone }}<br />
                          {{ billing.email }}<br />
                          {{ billing.vat ? `${$t('VAT')} ${billing.vat}` : '' }}<br v-if="billing.vat" />
                          {{ billing.contract ? $t("Vertragspartner") : "" }}
                        </div>
                        <div v-else>
                          <v-alert class="mt-2" type="error" outlined dense>{{
                            $t("Rechnungsanschrift unvollständig!")
                          }}</v-alert>
                        </div>
                      </div>
                      <v-spacer v-if="delivery.enabled" />
                      <div v-if="delivery.enabled">
                        <strong>{{ $t("Lieferadresse") }}</strong><br />
                        <div v-if="sufficientContact(delivery)">
                          {{ delivery.institution }}<br />
                          {{
                            delivery.salutation &&
                            delivery.salutation != "keine"
                            ? delivery.salutation
                            : ""
                          }}
                          {{ delivery.firstname }} {{ delivery.lastname }}<br />
                          {{ delivery.street }}<br />
                          {{ delivery.zipcode }} {{ delivery.city }}<br />
                          {{
                            $t(
                              `country.${delivery.country.cover_code
                                ? delivery.country.cover_code + "_"
                                : ""
                              }${delivery.country.code}`
                            )
                          }}<br />
                          {{ delivery.phone }}<br />
                          {{ delivery.email }}<br />
                          {{ delivery.contract ? $t("Vertragspartner") : "" }}
                        </div>
                        <div v-else>
                          <v-alert class="mt-2" type="error" outlined dense>{{
                            $t("Lieferanschrift unvollständig!")
                          }}</v-alert>
                        </div>
                      </div>
                    </v-row>
                    <v-row>
                    <v-col align-self="start" cols="1">
                      <v-checkbox style="display:inline-block" v-model="ack" class="required"></v-checkbox>
                    </v-col>
                    <v-col cols="11">{{ $t('ack_kontakt') }}
                    </v-col>
                    </v-row>
                    <v-row v-if="!ack" class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-alert class="mt-2" type="error" outlined dense>{{
                          $t("Bitte bestätigen Sie die Prüfung Ihrer Kontaktdaten.")
                        }}</v-alert>
                      </v-col>
                    </v-row> 
                  </v-container>
                  
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>{{ $t("Bemerkungen Titel") }}</v-card-title>
                <v-card-text class="intro">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-textarea outlined dense v-model="remarks" :label="$t('Bemerkungen')"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="sendingFeedbackDialog" hide-overlay persistent width="400">
      <v-card>
        <v-card-title> {{ $t("Ihre Anfrage wird gesendet") }} </v-card-title>
        <v-card-text class="mt-1">
          <div v-if="sendingFeedbackState == 'IN_PROGRESS'">
            <v-progress-circular class="ma-2" indeterminate color="primary"></v-progress-circular><span class="px-1">{{ $t("Bitte warten...") }}</span>
          </div>
          <div v-else-if="sendingFeedbackState == 'DONE'">
            <v-alert prominent text type="success">{{
              $t("Ihre Anfrage wurde übermittelt.")
            }}</v-alert>
            {{ $t("Die Bestell-Referenznummer lautet") }}:<br />
            <b>{{ requestId }}</b><br />
            {{ $t("Info Bestell-Referenznummer") }}<br /><br />
            {{ $t("mail_soon") }}
            <div v-if="denv() && requestTimer > 0">
              <a target="_new" :href="`${apiPath()}/requests/${requestId}/xml`">XML</a>&nbsp;&nbsp;&nbsp;
              <a target="_new" :href="`${apiPath()}/requests/${requestId}/txt`">TXT</a>&nbsp;&nbsp;&nbsp;&nbsp;
              <!-- Show remaining seconds as circular progress-->
              <v-progress-circular :rotate="-90" :size="30" :width="5" :value="requestTimer * 10" color="primary">
                {{ requestTimer }}
              </v-progress-circular>

            </div>
          </div>
          <div v-else-if="sendingFeedbackState == 'FAIL'">
            <v-alert prominent text type="error">
              {{ $t("Ihre Anfrage konnte nicht übermittelt werden.") }}
              </v-alert>
              <div v-html="$t('Ein Fehler ist aufgetreten')">
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="['DONE','FAIL'].includes(sendingFeedbackState)" @click="(sendingFeedbackDialog = false,sendingFeedbackState='NONE')">
            {{ $t("Schliessen") }}
          </v-btn>
          <!--
          <v-btn
            color="primary"
            v-if="sendingFeedbackState == 'IN_PROGRESS'"
            @click="sendingFeedbackState = 'DONE'"
          >
            DONE
          </v-btn>
          <v-btn
            color="primary"
            v-if="sendingFeedbackState == 'NONE'"
            @click="sendingFeedbackState = 'IN_PROGRESS'"
          >
            IN_PROGRESS
          </v-btn>
          -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import kosten from "@/data/kosten.json";
import countries from "@/data/countries";
import { addDays, format, parseISO } from "date-fns"; // We use date-fns library to add days to a date
import ArticleInput from './ArticleInput.vue';

const kosten = [];
export default {
  components: {
    ArticleInput
  },
  props: {
    articleId: String,
    locale: String,
  },
  data() {
    return {
      requestTimer: 0,
      prodTypesToShowAbove: ['PART', 'CHP', 'KA', 'CD', 'ALB'],
      menu: false,
      sendingFeedbackDialog: false,
      sendingFeedbackState: "NONE",
      articleInfo: [],
      requestId: "",
      e1: 1,
      valid: true,
      name: "",
      inputRequiredRules: [
        (v) => !!v || this.$t("Input is required"),
      ],
      nameRules: [
        (v) => !!v || this.$t("Input is required"),
        (v) => (v && v.length <= 45) || "Input must be less than 45 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || this.$t("E-mail is required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("E-mail must be valid"),
      ],
      phoneRules: [
        (v) => !!v || this.$t("Input is required"),
        (v) => (v && v.length <= 45) || "Input must be less than 45 characters",
        (v) =>
          v
            .split("")
            .map((c) => c.charCodeAt(0))
            .every(
              (charCode) =>
                (charCode >= 48 && charCode <= 57) ||
                [32, 40, 41, 43, 45].includes(charCode)
            ) || this.$t("Input must be a valid phone number"),
      ],
      aboveOrEqual0AndIntegerRules: [
        (v) => Number.isInteger(Number(v)) || this.$t("Muss ganzzahlig sein!"),
        (v) => Number(v) >= 0 || this.$t("Muss >= 0 sein!"),
      ],
      aboveOrEqual0Rules: [(v) => v >= 0 || this.$t("Muss >= 0 sein!")],
      material: {
        digital: false
      },
      x_panel: null,
      remarks: "",
      interpret: {
        dirigent: "",
        chorname: "",
        orchester: "",
        orchesterart: "berufsorchester",
        orchestereinstufung: "UNBEKANNT",
        anzsaenger: 0,
        keineaufzeichnung: true,
        audiovisuelleaufzeichnung: false,
        audioaufzeichnung: false,
        tv: false,
        radio: false,
        youtube: false,
        website: false,
        other: "",
      },
      auffuehrung: {
        gesamtauffuehrung: true,
        auffuehrungsteile: "",
        auffuehrungsart: "konzertant",
        ergaenzung: "",
        termine: [],
      },
      contact: {
        contract: true,
        deliverydate: addDays(new Date(), 7).toISOString().substring(0, 10),
        deliveryacceptcost: false,
        institution: "",
        firstname: "",
        lastname: "",
        street: "",
        zipcode: "",
        city: "",
        country: "",
        phone: "",
        fax: "",
        email: "",
        organizer: "",
      },
      ack: false,
      billing: {
        contract: false,
        enabled: false,
        institution: "",
        firstname: "",
        lastname: "",
        street: "",
        zipcode: "",
        city: "",
        country: "",
        phone: "",
        fax: "",
        email: "",
        organizer: "",
      },
      delivery: {
        contract: false,
        enabled: false,
        institution: "",
        firstname: "",
        lastname: "",
        street: "",
        zipcode: "",
        city: "",
        country: "",
        phone: "",
        fax: "",
        email: "",
        organizer: "",
      },
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
      // orchestereinstfung A,B,C,D and S
      orchestereinstufung: [
        {
          id: "UNBEKANNT",
          bez: "Einstufung ist mir nicht bekannt",
        },
        {
          id: "ORCHKAT_A",
          bez: "Kategorie A",
        },
        {
          id: "ORCHKAT_B",
          bez: "Kategorie B",
        },
        {
          id: "ORCHKAT_C",
          bez: "Kategorie C",
        },
        {
          id: "ORCHKAT_D",
          bez: "Kategorie D",
        },
        {
          id: "ORCHKAT_S",
          bez: "Kategorie S",
        },
        {
          id: "ORCHKAT_KM",
          bez: "Laienorchester",
        },
      ],
    };
  },

  computed: {
    panel: {
      get() {
        return this.einzelstimmendisabled ? null : this.x_panel;
      },
      set(val) {
        this.x_panel = val;
      },
    },
    headers() {
      return [
        {
          text: this.$t("Datum"),
          value: "date",
          width: "9em",
          sortable: false,
        },
        {
          text: this.$t("Stadt"),
          value: "city",
          editable: true,
          sortable: false,
        },
        {
          text: this.$t("Veranstaltungsraum"),
          value: "location",
          sortable: false,
        },
        {
          text: this.$t("Sitzplätze"),
          value: "seats",
          width: "9em",
          sortable: false,
        },
        {
          text: this.$t("Eintritt"),
          value: "price",
          width: "11em",
          sortable: false,
        },
        {
          text: this.$t("Lizenzkosten"),
          value: "fee",
          width: "5em",
          sortable: false,
        },
        { value: "actions" },
      ];
    },
    canCalculateFeeForRentalMaterial() {
      return this.auffuehrung.gesamtauffuehrung && this.hasSpecifiedKosten;
    },
    hasSpecifiedKosten() {
      return this.articleInfoLEIH && (this.articleInfoLEIH.kosten || (this.articleInfoLEIH.kosten_spez && this.articleInfoLEIH.kosten_spez.length))
    },
    isFall01() {
      return !!(this.articleInfoLEIH.merkmale_mapped?.LEIH_BER?.qaus_id === "FALL_01")
    },
    hasOrchkatKM() {
      return !!(this.articleInfoLEIH?.kosten_spez?.find((k) => k.m_preis_grp === "ORCHKAT_KM"));
    },
    katPrices() {
      let katPrices = (this.articleInfoLEIH.kosten_spez || []).filter(
        (k) => k.m_preis_grp === (this.hasOrchkatKM ? 'ORCHKAT_KM' : this.interpret.orchestereinstufung)
      ).map((k) => k);
      if (katPrices.length === 0) {
        katPrices = (this.articleInfoLEIH.kosten || []).map((k) => k);
      }

      // If katPrices is of length 4, just pick entries 0 and 2
      // This is to "map" the FALL_01 way of specifying prices
      // to the "normal" with/without entry fee
      /*
      if (katPrices.length === 4) {
        katPrices.splice(1, 1);
        katPrices.splice(2, 1);
      }*/
      return katPrices;
    },
    effectiveKosten() {
      return (this.isFall01 ? this.katPrices : (this.hasOrchkatKM ? this.articleInfoLEIH.kosten_spez : this.articleInfoLEIH.kosten)) || [];
    },
    formattedDeliveryDate() {
      if (this.contact.deliverydate) {
        const dateObj = parseISO(this.contact.deliverydate);
        return format(dateObj, "dd.MM.yyyy"); // Adjust format string here
      }
      return null;
    },
    minDate() {
      const today = new Date();
      const inOneWeek = addDays(today, 7);

      return format(inOneWeek, "yyyy-MM-dd");
    },
    einzelstimmendisabled() {
      return this.material.digital || this.leihmaterialtolist
        .filter(
          (l) =>
            !this.prodTypesToShowAbove.includes(l.prod_typ) &&
            (!["STSE", "ST-D"].includes(l.prod_typ) ||
              (l.merkmale_mapped.INHALT &&
                l.merkmale_mapped.INHALT.qaus_id === "HARM"))
        )
        .every(this.entryDisabled);
    },
    articleInfoLIZ() {
      return this.articleInfo.find((ai) => ai.perf_prod_typ === "LIZ");
    },
    articleInfoLIZKostenSorted() {
      return this.articleInfoLIZ.kosten.slice(0).sort((a, b) => {
        // Determine a sort key to compare the two objects
        // In case aufl_text ends with "ohne Eintritt", start the key with "1", else with "0"
        // Then, add the m_preis_grp to the key
        const aKey = `${a.aufl_text.endsWith("ohne Eintritt") ? "1" : "0"}${a.m_preis_grp}`;
        const bKey = `${b.aufl_text.endsWith("ohne Eintritt") ? "1" : "0"}${b.m_preis_grp}`;

        return aKey.localeCompare(bKey);
      });
    },
    articleInfoLEIH() {
      return this.articleInfo.find((ai) => ai.perf_prod_typ === "LEIH");
    },
    effectiveKostenSorted() {
      return this.effectiveKosten.slice(0).sort((a, b) => {
        // Determine a sort key to compare the two objects
        // In case aufl_text ends with "ohne Eintritt", start the key with "1", else with "0"
        // Then, add the m_preis_grp to the key
        const aKey = `${a.aufl_text.endsWith("ohne Eintritt") ? "1" : "0"}${a.m_preis_grp}_${a.auflage_nr}`;
        const bKey = `${b.aufl_text.endsWith("ohne Eintritt") ? "1" : "0"}${b.m_preis_grp}_${b.auflage_nr}`;

        return aKey.localeCompare(bKey);
      });    
    },
    countries() {
      return countries.toSorted((a, b) =>
        this.$t(
          `country.${a.cover_code ? a.cover_code + "_" : ""}${a.code}`
        ).localeCompare(
          this.$t(`country.${b.cover_code ? b.cover_code + "_" : ""}${b.code}`)
        )
      );
    },
    leihmaterialtolist() {
      const lm = this.articleInfo[0].leihmaterial.filter((lm) =>
        this.material.digital
          ? lm.prod_typ.endsWith("-D")
          : !lm.prod_typ.endsWith("-D")
      );
      lm.sort((a, b) =>
        a.merkmale_mapped.INHALT &&
          a.merkmale_mapped.INHALT.qaus_id === "ORCHSET"
          ? -1
          : b.merkmale_mapped.INHALT &&
            b.merkmale_mapped.INHALT.qaus_id === "ORCHSET"
            ? 1
            : a.artikel_nr.localeCompare(b.artikel_nr)
      );
      return lm;
    },
  },
  methods: {
    isPhoneNumberCharacter(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      // Numbers (0-9)
      if (charCode >= 48 && charCode <= 57) return true;

      // Hyphen, parentheses, space, plus sign
      if (
        charCode === 45 ||
        charCode === 40 ||
        charCode === 41 ||
        charCode === 32 ||
        charCode === 43
      ) {
        return true;
      }

      // Prevent other characters
      evt.preventDefault();
    },
    log(x, y, z) {
      console.log(x, y, z);
    },
    istodayorpast(_date) {
      // Checks if date lies before current time
      const date = new Date(_date);
      const today = new Date();
      return date <= today;

    },
    canSend() {
      return (
        this.ack &&
        this.sufficientContact(this.contact, false, true) &&
        (!this.billing.enabled || this.sufficientContact(this.billing, true)) &&
        (!this.delivery.enabled ||
          this.sufficientContact(this.delivery)) &&
        this.auffuehrung.termine.length &&
        this.interpret.chorname &&
        this.interpret.dirigent &&
        this.interpret.orchester &&
        this.auffuehrung.termine.every(
          (t) => t.city && t.location && !this.istodayorpast(t.date)
        ) &&
        (!!this.auffuehrung.gesamtauffuehrung || !!this.auffuehrung.auffuehrungsteile)
      );
    },
    sufficientContact(contact, nophone = false, organizerrequired = false) {
      return (
        contact.firstname &&
        contact.lastname &&
        contact.street &&
        contact.zipcode &&
        contact.city &&
        contact.country &&
        (nophone || contact.phone) &&
        contact.email &&
        (!organizerrequired || contact.organizer)
      );
    },
    entryDisabled(e) {
      if (this.prodTypesToShowAbove.includes(e.prod_typ)) return false;
      if (["STSE", "ST-D"].includes(e.prod_typ)) {
        if (
          e.merkmale_mapped.INHALT &&
          e.merkmale_mapped.INHALT.qaus_id === "ORCHSET"
        ) {
          return false;
        }
        for (const [artikel_nr, count] of Object.entries(this.material)) {
          if (
            count > 0 &&
            ((this.articleInfoLEIH||this.articleInfoLIZ).leihmaterial || []).find(
              (lm) =>
                lm.artikel_nr === artikel_nr &&
                (lm.prod_typ.startsWith("STSE") ||
                  lm.prod_typ.startsWith("ST-D")) &&
                lm.merkmale_mapped.INHALT &&
                lm.merkmale_mapped.INHALT.qaus_id === "ORCHSET"
            )
          ) {
            return true;
          }
        }
        return false;
      }
      for (const [artikel_nr, count] of Object.entries(this.material)) {
        if (
          count > 0 &&
          ((this.articleInfoLEIH||this.articleInfoLIZ).leihmaterial || []).find(
            (lm) =>
              lm.artikel_nr === artikel_nr &&
              (lm.prod_typ.startsWith("STSE") || lm.prod_typ.startsWith("ST-D")) &&
                lm.merkmale_mapped.INHALT &&
                lm.merkmale_mapped.INHALT.qaus_id === "ORCHSET"
          )
        ) {
          //console.log("entryDisabled", e, (this.articleInfoLEIH||this.articleInfoLIZ).leihmaterial);
          return true;
        }
      }
      return false;
    },
    formatDate(dt) {
      return this.$options.filters.formatDate(dt, "DD.MM.YYYY");
    },
    parseDate(dt) {
      return this.$options.filters.formatDate(dt, "DD.MM.YYYY");
    },
    getFeeForRentalMaterial(termin, idx) {
      if (!this.canCalculateFeeForRentalMaterial) return 0;

      //const effective_kosten = this.hasOrchkatKM ? this.articleInfoLEIH.kosten_spez : this.articleInfoLEIH.kosten;
      const effective_kosten = this.effectiveKosten;

      //return (this.isFall01 ? this.katPrices : (this.hasOrchkatKM ? this.articleInfoLEIH.kosten_spez : this.articleInfoLEIH.kosten)) || [];


      /*
      effectiveKosten() {
      return this.isFall01 ? this.katPrices : (this.articleInfoLEIH.kosten || this.articleInfoLEIH.kosten_spez || [])
    },*/

      if (this.isFall01) {

        if ([1, 2, 4, 5].includes(idx)) {
          return 0;
        }

        if ( effective_kosten.length < 2 ) {
          return 0;
        }

        if ([0].includes(idx)) {
          return effective_kosten[0].preis;
        }
        if ([3].includes(idx)) {
          return effective_kosten[1].preis;
        }

        return effective_kosten[1].preis;
        /*
        const katPrices = this.katPrices

        if (katPrices.length < 4) {
          return 0;
        }

        if ([0].includes(idx)) {
          return katPrices[0].preis;
        }
        if ([1, 2, 4, 5].includes(idx)) {
          return 0;
        }
        if ([3].includes(idx)) {
          return katPrices[1].preis;
        }

        return katPrices[1].preis;
*/
      }

      if (termin.price) {
        if ([0].includes(idx)) {
          return effective_kosten[0].preis;
        }
        return effective_kosten[2].preis;
      }

      if ([0].includes(idx)) {
        return effective_kosten[1].preis;
      }

      return effective_kosten[3].preis;
    },
    getLicenseFeeForPerformance(termin, idx) {
      if (!this.articleInfoLIZ || !this.articleInfoLIZ.kosten || this.articleInfoLIZ.kosten.length < 4) return 0;
      if (termin.price) {
        if (idx === 0) {
          return this.articleInfoLIZ.kosten[0].preis;
        }
        return this.articleInfoLIZ.kosten[2].preis;
      }

      if (idx === 0) {
        return this.articleInfoLIZ.kosten[1].preis;
      }

      return this.articleInfoLIZ.kosten[3].preis;
    },
    async fetchArticleInfo() {
      this.articleInfo = kosten.filter(
        (d) => d.haupt_artikel_nr === this.articleId
      );
      if (this.articleInfo.length === 0) {
        // Fetch from API
        const response = await fetch(
          `${this.apiPath()}/articleinfo/${this.articleId}`
        );
        this.articleInfo = await response.json();
      }
      //console.log(this.articleInfo);
    },
    async postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: new Headers({ "content-type": "application/json" }),
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });

      if ( !response.ok ) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); // parses JSON response into native JavaScript objects
    },
    validate() {
      this.$refs.form.validate();
    },
    addTermin() {
      const dt = new Date().toISOString().slice(0, 10);
      this.auffuehrung.termine.push({
        date: dt,
        city: "",
        location: "",
        size: 0,
        seats: 0,
        price: 0,
      });
    },
    deleteTermin(item) {
      this.auffuehrung.termine.splice(
        this.auffuehrung.termine.indexOf(item),
        1
      );
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    denv() {
      return window.location.hostname.includes("localhost") || window.location.hostname.includes("-dev")
    },
    apiPath() {
      let apiHostname = window.location.hostname;
      if (apiHostname.startsWith("client")) {
        apiHostname = apiHostname.replace("client", "api");
      } else if (apiHostname.startsWith("localhost")) {
        apiHostname = "localhost:3000";
      } else if (apiHostname.startsWith("192.168")) {
        apiHostname = `${apiHostname}:3000`;
      } else {
        apiHostname = `api.${apiHostname}`;
      }

      return `${window.location.protocol}//${apiHostname}/${process.env.VUE_APP_API_BASE_PATH || ""
        }`;
    },
    checkAndSend() {
      const lm = this.leihmaterialtolist
        .filter(
          (x) =>
            x.artikel_nr in this.material &&
            this.material[x.artikel_nr] > 0 &&
            !this.entryDisabled(x)
        )
        .reduce(
          (p, c) => ((p[c.artikel_nr] = this.material[c.artikel_nr]), p),
          {}
        );
      const zm = this.articleInfo[0].zusatzmaterial
        .filter(
          (x) =>
            x.artikel_nr in this.material &&
            this.material[x.artikel_nr] > 0
        )
        .reduce(
          (p, c) => ((p[c.artikel_nr] = this.material[c.artikel_nr]), p),
          {}
        );
      // Set country property of contact, billing and delivery to DE in case it's not set
      if (!this.contact.country) {
        this.contact.country = countries.find(
          (c) => c.code === 'DE'
        );
      }
      if (!this.billing.country) {
        this.billing.country = countries.find(
          (c) => c.code === 'DE'
        );
      }
      if (!this.delivery.country) {
        this.delivery.country = countries.find(
          (c) => c.code === 'DE'
        );
      }
      const dataToSubmit = {
        artikelInfo: {
          autoren_formatiert: this.articleInfo[0].autoren_formatiert,
          artikel_nr: this.articleInfo[0].haupt_artikel_nr,
          titel1: this.articleInfo[0].i18n[this.$i18n.locale].titel1,
          liz_kosten: (this.articleInfoLIZ || {}).kosten,
          leih_kosten: (this.articleInfoLEIH || {}).kosten,
          liz_artikel_nr: this.articleInfoLIZ
            ? this.articleInfoLIZ.artikel_nr
            : null,
          leih_artikel_nr: this.articleInfoLEIH
            ? this.articleInfoLEIH.artikel_nr
            : null,
        },
        material: lm,
        zusatzmaterial: zm,
        interpret: this.interpret,
        auffuehrung: this.auffuehrung,
        contact: this.contact,
        billing: this.billing,
        delivery: this.delivery,
        remarks: this.remarks || "",
        locale: this.$i18n.locale || 'de',
      };
      this.sendingFeedbackDialog = true;
      this.sendingFeedbackState = "IN_PROGRESS";

      this.postData(`${this.apiPath()}/requestform`, dataToSubmit).then(
        (data) => {
          this.sendingFeedbackState = "DONE";
          this.requestId = data.requestId;

          if (this.denv()) {
            this.requestTimer = 10;
            const interval = setInterval(() => {
              this.requestTimer--;
            }, 1000);
            setTimeout(() => {
              clearInterval(interval);
            }, 10001);
          }

        }
      ).catch((e) => {
        this.sendingFeedbackState = "FAIL";
        console.error(e);
      })

      //console.log(dataToSubmit);
    },
  },
  watch: {
    auffuehrung: {
      handler(val) {
        localStorage.setItem(
          `${this.articleId}_auffuehrung`,
          JSON.stringify(val)
        );
      },
      deep: true,
    },
    interpret: {
      handler(val) {
        localStorage.setItem(
          `${this.articleId}_interpret`,
          JSON.stringify(val)
        );
      },
      deep: true,
    },
    contact: {
      handler(val) {
        if (val.contract) {
          this.billing.contract = false;
          this.delivery.contract = false;
        }
        localStorage.setItem(`${this.articleId}_contact`, JSON.stringify(val));
      },
      deep: true,
    },
    billing: {
      handler(val) {
        if (val.contract) {
          this.contact.contract = false;
          this.delivery.contract = false;
        }
        localStorage.setItem(`${this.articleId}_billing`, JSON.stringify(val));
      },
      deep: true,
    },
    delivery: {
      handler(val) {
        if (val.contract) {
          this.contact.contract = false;
          this.billing.contract = false;
        }
        localStorage.setItem(`${this.articleId}_delivery`, JSON.stringify(val));
      },
      deep: true,
    },
    material: {
      handler(val) {
        localStorage.setItem(`${this.articleId}_material`, JSON.stringify(val));
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchArticleInfo().then(() => {
      if (localStorage.getItem(`${this.articleId}_auffuehrung`)) {
        this.auffuehrung = JSON.parse(
          localStorage.getItem(`${this.articleId}_auffuehrung`)
        );
      }
      if (localStorage.getItem(`${this.articleId}_interpret`)) {
        this.interpret = JSON.parse(
          localStorage.getItem(`${this.articleId}_interpret`)
        );
      }
      if (localStorage.getItem(`${this.articleId}_contact`)) {
        this.contact = JSON.parse(
          localStorage.getItem(`${this.articleId}_contact`)
        );
      }
      if (localStorage.getItem(`${this.articleId}_billing`)) {
        this.billing = JSON.parse(
          localStorage.getItem(`${this.articleId}_billing`)
        );
      }
      if (localStorage.getItem(`${this.articleId}_delivery`)) {
        this.delivery = JSON.parse(
          localStorage.getItem(`${this.articleId}_delivery`)
        );
      }
      if (localStorage.getItem(`${this.articleId}_material`)) {
        this.material = JSON.parse(
          localStorage.getItem(`${this.articleId}_material`)
        );
      }

      // Set the country preselection to the country of the user
      // ONLY IF the current locale is set to 'de'
      if (this.contact.country === "" && this.$i18n.locale === 'de') {
        this.contact.country = countries.find(
          (c) => c.code === this.$i18n.locale.toUpperCase()
        ) || countries.find(
          (c) => c.code === 'DE'
        );
      }
      if (this.billing.country === "" && this.$i18n.locale === 'de') {
        this.billing.country = countries.find(
          (c) => c.code === this.$i18n.locale.toUpperCase()
        ) || countries.find(
          (c) => c.code === 'DE'
        );
      }
      if (this.delivery.country === "" && this.$i18n.locale === 'de') {
        this.delivery.country = countries.find(
          (c) => c.code === this.$i18n.locale.toUpperCase()
        ) || countries.find(
          (c) => c.code === 'DE'
        );
      }

    });
  },
};
</script>

<style>
.v-textarea textarea {
  line-height: unset;
}

.content {
  width: 100%;
  display: flex;
}

.content_left {
  margin-right: 10mm;
}

.disabled {
  color: lightgray;
}

.title1 {
  font-size: 30px !important;
  line-height: 1.2 !important;
  font-weight: normal !important;
  font-style: normal !important;
  color: #4d4c41 !important;
}

.intro {
  font-size: 16px;
  line-height: 24px !important;
  font-weight: normal !important;
  font-style: normal !important;
  color: #4d4c41 !important;
}

.rentalform {
  color: #4d4c41 !important;
}

.v-application .primary {
  background-color: #06c !important;
  border-color: #06c !important;
}

.v-btn {
  border-radius: 0 !important;
  text-transform: none !important;
  font-size: 16px !important;
  letter-spacing: normal !important;
}

.v-stepper__step__step {
  border-radius: 0 !important;
}

.toprow {
  margin-bottom: 1em;
}

.card .card-header,
.v-card__title {
  border: none;
  border-radius: 0;
  padding: 12px 12px;
  font-size: 21px;
  line-height: 24px;
  position: relative;
  background-color: #f0f0f0;
}

.card-title,
.v-card__title {
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}

.v-sheet.v-card {
  border-radius: 0;
  border: 0;
  box-shadow: 0 !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: unset !important;
}

.INHALT_ORCHSET {
  font-weight: bold;
}

.required fieldset {
  border-width: 2px;
}

.required label {
  color: black;
  font-weight: bold;
}
</style>