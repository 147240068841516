import Vue from 'vue'
import VueRouter from 'vue-router'
import RentalMaterialView from '../views/RentalMaterialView.vue'
import StartView from '../views/StartView.vue'
import i18n from "@/plugins/i18n";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `${i18n.locale}`,
  },
  {
    path: '/:locale',
    component: StartView,
    props: true,
    beforeEnter: (to, from, next) => {
      const pattern = /^[a-z]{2}$/i;
      if (pattern.test(to.params.locale)) {
        next();
      } else {
        next(`/${i18n.locale}/${to.params.locale}`)
      }
    }
  },
  {
    path: '/:articleId',
    redirect: `${i18n.locale}/:articleId`
  },
  {
    path: '/:locale/:articleId',
    name: 'rentalmaterial',
    component: RentalMaterialView,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const pattern = /^[a-z]{2}$/i;
  /////
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);


  if (!to.params.locale) {
    return next({ name: to.name, params: { ...to.params, locale: i18n.locale } });
  }
  if (to.params.locale && i18n.locale != to.params.locale && pattern.test(to.params.locale)) {
    i18n.locale = to.params.locale;
  }

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  } else {
    document.title = i18n.t('app.title');
  }
  return next();
});



export default router;
