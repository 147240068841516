<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="3" class="text-left">
          <v-img
            class="ma-2"
            max-height="40"
            max-width="200"
            src="https://www.carus-verlag.com/out/wave-carus/img/logo.png"
          ></v-img>
        </v-col>
        <v-col cols="6" class="text-left" align-self="end">
          <span
            class="ma-2"
            style="font-size: 16px; font-weight: 700; color: rgb(0, 102, 204)"
            >{{ $t("Aufführungslizenzen / Leihmaterial") }}</span
          >
        </v-col>

        <v-col
          cols="3"
          class="text-right"
          style="font-size: 12px; font-weight: bold; color: rgb(0, 102, 204)"
        >
          <a @click="setLocale('de')" class="mr-2">DE</a>
          <a @click="setLocale('en')" class="mr-2">EN</a>
          <a @click="setLocale('fr')">FR</a>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="d-flex justify-center flex-column"
      style="padding: 2em 5em 3em 5em"
    >
      <div v-if="setlocale == 'de'">
        <h3>Herzlich willkommen im Auff&uuml;hrungsportal von Carus!</h3>
        <p>Hier k&ouml;nnen Sie &hellip;</p>
        <ul>
          <li>
            Ihre geplanten Auff&uuml;hrungen von szenischen Werken, v.a. von
            Kindermusicals und Singspielen anmelden
          </li>
          <li>
            die anfallenden Kosten f&uuml;r das &bdquo;Gro&szlig;e Recht&ldquo;
            in Abh&auml;ngigkeit von Terminen und Eintrittskosten im Voraus
            berechnen
          </li>
          <li>
            in vielen F&auml;llen die Geb&uuml;hren f&uuml;r leihweise
            verf&uuml;gbare Noten in Abh&auml;ngigkeit von der Einstufung Ihres
            Orchesters kalkulieren
          </li>
          <li>
            alle Infos mitteilen, die wir ben&ouml;tigen, um Ihnen ein
            individuelles Angebot zu erstellen
          </li>
          <li>
            vorhandenes Noten- und Zusatzmaterial, wie z.B. Playbacks oder
            XML-Dateien recherchieren
          </li>
        </ul>
        <p>
          Wir m&ouml;chten Sie bei Ihrer Planung von musikalischen
          Auff&uuml;hrungen unterst&uuml;tzen. Denn es gibt hier einiges zu
          beachten.
        </p>
        <ul>
          <li>
            Szenische Auff&uuml;hrungen von Kindermusicals oder Opern
            m&uuml;ssen rechtzeitig vor der Auff&uuml;hrung beim Verlag als
            Rechteinhaber angemeldet und lizenziert werden. Die Rechte
            szenischer Auff&uuml;hrungen werden vom Verlag selbst wahrgenommen
            und nicht von Verwertungsgesellschaften.
          </li>
          <li>
            Konzertante Auff&uuml;hrungen dieser Werke (dies gilt auch f&uuml;r
            konzertante Auff&uuml;hrungen von Kindermusicals und Opern)
            m&uuml;ssen bei den jeweils zust&auml;ndigen nationalen
            Verwertungsgesellschaften (in Deutschland z.B der GEMA) rechtzeitig
            vor der Auff&uuml;hrung angemeldet und lizenziert werden.
          </li>
        </ul>
        <p>
          F&uuml;r bestimmte Werke, konzertant oder szenisch, liefern wir die
          Orchesterstimmen leihweise aus.
        </p>
        <p>
          Mit unserem Auff&uuml;hrungsportal k&ouml;nnen Sie diese Kosten
          f&uuml;r viele Musikwerke selbst kalkulieren und uns gleichzeitig alle
          Daten f&uuml;r die Vertragserstellung &uuml;bermitteln. Damit Sie
          selbst sich schnell wieder auf Ihre eigentliche Aufgabe fokussieren
          k&ouml;nnen &ndash; Musik zu machen!
        </p>
      </div>
      <div v-if="setlocale == 'en'">
        <h3>Welcome to the Carus Performance Portal!</h3>
        <p>Here you can:</p>
        <ul>
          <li>
            Register forthcoming performances of your staged productions,
            particularly children&rsquo;s musicals and musical comedies
          </li>
          <li>
            Calculate in advance the &ldquo;grand rights&rdquo; performance
            costs depending on dates and ticket prices
          </li>
          <li>
            Calculate the fees for any sheet music or scores available for loan,
            depending on the classification of your orchestra
          </li>
          <li>
            Tell us all the information we need in order to create an
            individualized quote for you
          </li>
          <li>
            Find out about any available scores or additional materials, such as
            Playbacks or XML-files.
          </li>
        </ul>
        <p>
          We want to support you as you plan your musical performances, because
          there are some things to consider here:
        </p>
        <ul>
          <li>
            <strong>Staged</strong> performances of children&lsquo;s musicals or
            operas must be registered in advance and licensed by the publisher
            as copyright holder. The publisher holds the rights to staged
            performances, not the national collection organization.
          </li>
          <li>
            <strong>Concert</strong> performances of these works (this includes
            concert performances of children&rsquo;s musicals and musical
            comedies) must be registered with and licensed by the respective
            national collection society in advance of the performance; in
            Germany, for instance, the relevant body is GEMA.
          </li>
        </ul>
        <p>
          For certain works, whether staged or in concert performance, we can
          supply the orchestral parts on loan.
        </p>
        <p>
          Our Performance Portal allows you to calculate such costs for many
          musical performances yourself, and at the same time you can provide us
          with all the necessary data to enable us to put together our proposed
          agreement for you. So you can get back to concentrating on your real
          task &ndash; making music!
        </p>
      </div>
      <div v-if="setlocale == 'fr'">
        <h3>
          Bienvenue sur le portail d&eacute;di&eacute; aux
          repr&eacute;sentations de Carus
        </h3>
        <p>Ici, vous pourrez&hellip;</p>
        <ul>
          <li>
            d&eacute;clarer les repr&eacute;sentations pr&eacute;vues des
            &oelig;uvres sc&eacute;niques, avant tout les com&eacute;dies
            musicales pour enfants et les vaudevilles
          </li>
          <li>
            calculer d&rsquo;avance les frais du &laquo; Gro&szlig;es Recht
            &raquo; (droits d&rsquo;auteur pour une &oelig;uvre sc&eacute;nique)
            en fonction des dates et des droits d&rsquo;entr&eacute;e
          </li>
          <li>
            calculer pour de nombreux cas les redevances pour les partitions
            disponibles en location en fonction de la cat&eacute;gorie de votre
            orchestre
          </li>
          <li>
            communiquer toutes les informations dont nous avons besoin pour
            &eacute;tablir votre devis personnalis&eacute;
          </li>
          <li>
            rechercher les partitions et mat&eacute;riel compl&eacute;mentaire
            disponibles, par exemple des play-back ou des fichiers XML.
          </li>
        </ul>
        <p>
          Nous souhaitons vous aider &agrave; planifier vos
          repr&eacute;sentations musicales. En effet, il faut veiller &agrave;
          certains points.
        </p>
        <ul>
          <li>
            Les repr&eacute;sentations sc&eacute;niques de com&eacute;dies
            musicales pour enfants ou d&rsquo;op&eacute;ras doivent &ecirc;tre
            d&eacute;clar&eacute;es en temps voulu avant la
            repr&eacute;sentation &agrave; l&rsquo;&eacute;diteur
            d&eacute;tenteur des droits et faire l&rsquo;objet d&rsquo;une
            licence. Les droits des repr&eacute;sentations sc&eacute;niques sont
            per&ccedil;us par l&rsquo;&eacute;diteur lui-m&ecirc;me et non par
            des soci&eacute;t&eacute;s de gestion des droits d&rsquo;auteur.
          </li>
          <li>
            Les repr&eacute;sentations concertantes de ces &oelig;uvres
            (&eacute;galement les repr&eacute;sentations concertantes de
            com&eacute;dies musicales pour enfants et d&rsquo;op&eacute;ras)
            doivent &ecirc;tre d&eacute;clar&eacute;es en temps voulu avant la
            repr&eacute;sentation &agrave; la soci&eacute;t&eacute; de gestion
            comp&eacute;tente pour le pays (par exemple la GEMA pour
            l&rsquo;Allemagne) et faire l&rsquo;objet d&rsquo;une licence.
          </li>
        </ul>
        <p>
          Pour certaines &oelig;uvres, concertantes ou sc&eacute;niques, nous
          fournissons les parties d&rsquo;orchestre en location.
        </p>
        <p>
          Notre portail d&eacute;di&eacute; vous permet de calculer
          vous-m&ecirc;me les co&ucirc;ts pour de nombreuses &oelig;uvres
          musicales et de nous communiquer toutes les donn&eacute;es utiles pour
          &eacute;tablir un contrat. Pour que vous puissiez rapidement vous
          concentrer sur votre objectif r&eacute;el&nbsp;: faire de la
          musique&nbsp;!
        </p>
      </div>
      <div class="d-flex justify-center align-center flex-column mt-3">
        <a
          :href="teaser.link"
          target="_blank"
          v-for="(teaser, idx) in teasers[setlocale]"
          :key="idx"
          class="my-2"
        >
          <v-img max-width="600" :src="teaser.img" :href="teaser.link"></v-img>
        </a>
      </div>
    </div>
    <span style="font-size: 10px; color: lightgray"
      >{{ config.product.name }} {{ config.product.version }}
      {{ config.product.gitsha }}</span
    >
  </div>
</template>

<script>
// @ is an alias to /src
import config from "@/configs";

export default {
  props: {
    locale: String,
  },
  name: "StartView",
  methods: {
    setLocale(locale) {
      locale != this.locale &&
        this.$router.push({
          path: `/${locale}`,
          replace: true,
        });
    },
  },
  mounted() {
    //this.setlocale = this.$i18n.locale;
  },
  data() {
    return {
      config,
      //setlocale: "de",
      teasers: {
        de: [
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/musiktheater_de.png",
            link: "https://www.carus-verlag.com/spotlight/singen-mit-kindern-und-jugendlichen/",
          },
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/grwerkeklbesetzung_de.png",
            link: "https://www.carus-verlag.com/produkte/editionsreihen/grosse-werke-in-kleiner-besetzung/",
          },
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/opernundsingspiele_de.png",
            link: "https://www.carus-verlag.com/chormusik/chormusik-nach-gattungen/opern-und-singspiele/",
          },
        ],
        en: [
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/musiktheater_en.png",
            link: "https://www.carus-verlag.com/en/spotlight/singing-with-children-and-young-people/",
          },
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/grwerkeklbesetzung_en.png",
            link: "https://www.carus-verlag.com/en/products/thematic-series/great-choral-works-in-small-scorings/",
          },
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/opernundsingspiele_en.png",
            link: "https://www.carus-verlag.com/en/choral-music/choral-music-by-genre/opera-singspiel/",
          },
        ],
        fr: [
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/musiktheater_fr.png",
            link: "https://www.carus-verlag.com/fr/spotlight/chanter-avec-les-enfants/",
          },
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/grwerkeklbesetzung_fr.png",
            link: "https://www.carus-verlag.com/fr/produits/series-editions/grande-oeuvres-chorales-pour-petit-effectif/",
          },
          {
            img: "https://media.carus-verlag.com/images-intern/medien/img/performance/opernundsingspiele_fr.png",
            link: "https://www.carus-verlag.com/fr/musique-chorale/musique-chorale-par-genre/opera-singspiel/",
          },
        ],
      },
    };
  },
  computed: {
    setlocale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>
.container {
  margin: 0;
}
h3 {
  margin-bottom: 1em;
}
ul {
  margin-bottom: 1em;
}
</style>
