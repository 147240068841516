import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import router from './router'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/cvnummer'
import './filters/uppercase'
import './filters/formatDate'
import './filters/formatCurrency'

// Firefox "polyfill" for Array.prototype.toSorted
if (!Array.prototype.toSorted) {
  Array.prototype.toSorted = function(compareFunction) {
    return this.slice().sort(compareFunction);
  }
}

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
