<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="3" class="text-left">
          <v-img
            class="ma-2"
            max-height="40"
            max-width="200"
            src="https://www.carus-verlag.com/out/wave-carus/img/logo.png"
          ></v-img>
        </v-col>
        <v-col cols="6" class="text-left" align-self="end">
          <span
            class="ma-2"
            style="font-size: 16px; font-weight: 700; color: rgb(0, 102, 204)"
            >{{ $t("Aufführungslizenzen / Leihmaterial") }}</span
          >
        </v-col>

        <v-col
          cols="3"
          class="text-right"
          style="font-size: 12px; font-weight: bold; color: rgb(0, 102, 204)"
        >
          <a @click="setLocale('de')" class="mr-2">DE</a>
          <a @click="setLocale('en')" class="mr-2">EN</a>
          <a @click="setLocale('fr')">FR</a>
        </v-col>
      </v-row>
    </v-container>
    <RentalMaterialForm :articleId="articleId" :locale="setlocale" />
    <span style="font-size: 10px; color: lightgray"
      >{{ config.product.name }} {{ config.product.version }}
      {{ config.product.gitsha }}</span
    >
  </div>
</template>

<script>
// @ is an alias to /src
import RentalMaterialForm from "@/components/RentalMaterialForm.vue";
import config from "@/configs";

export default {
  props: {
    articleId: String,
    locale: String,
  },
  name: "RentalMaterialView",
  components: {
    RentalMaterialForm,
  },
  methods: {
    setLocale(locale) {
      locale != this.locale &&
        this.$router.push({
          path: `/${locale}/${this.articleId}`,
          replace: true,
        });
    },
  },
  mounted() {
    this.setlocale = this.$i18n.locale;
  },
  data() {
    return {
      config,
      setlocale: "de",
    };
  },
};
</script>

<style scoped>
.container {
  margin:0;
}
</style>
