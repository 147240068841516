import Vue from 'vue'

Vue.filter('cvnummer', function (value) {
    // MUST start numeric!
    let result = (value||'').toString().trim()
    if ( ! result.match(/^\d+/) ) return value;
    const offset = (result.length === 6 || result.length === 12) ? 1 : 0;
    if (result.length === 7 || result.length === 6) {
        result = `${result.substr(0, 2 - offset)}.${result.substr(2 - offset, 3)}/${result.substr(
            5 - offset,
            2,
        )}`
    } else if (result.length === 13 || result.length === 12) {
        return `${result.substr(0, 2 - offset)}.${result.substr(2 - offset, 3)}/${result.substr(
            5 - offset,
            2,
        )}-${result.substr(7 - offset, 3)}-${result.substr(10 - offset, 3)}`
    }
    return result;
})