import Vue from 'vue'
import moment from 'moment-timezone'

const time = {
  // https://momentjs.com/timezone/docs/#/using-timezones/
  zone: 'Europe/Berlin',
  // https://momentjs.com/docs/#/displaying/format/
  format: 'DD.MM.YYYY H:mm'
}


Vue.filter('formatDate', (value, filterFormat) => {
  const { zone, format } = time
  

  if (value) {
    return moment(value).tz(zone).format(filterFormat || format || 'lll')
  }

  return ''
})
